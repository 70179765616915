export const environment = {
    production: true,
    staging: false,
    env: 'production',
    apiUrl: 'https://api.newcreation.org.sg',
    authApiUrl: 'https://api.newcreation.org.sg',
    rockKidzApiUrl: 'https://api.newcreation.org.sg',
    hubClientId: '139fc651-1c53-4eba-9d9b-7270499a1ebc',
    hubClientSecret: 'e813bd46-c888-466d-9053-1bebc7a15fe3',
    hubScopes: 'noahapi-userprofile userid profile noahapi-family noahapi-rockkidz',
    ONLINE_ENQUIRY_FORM_URL: 'https://ncc-it.zendesk.com/hc/en-sg/requests/new?ticket_form_id=1500000296661',
    reCaptchaSiteKey: '6LeokHsmAAAAAOt4kU4MQ9tDQt8UjMdAJP-cE_9G',
    httpCacheLifetime: 3600,
    appUrls: {
        nccga: 'https://nccgraceacademy.org',
        nccgaLogin: 'https://login.nccgraceacademy.org/hub'
    },
    releaseVersion: 'hub-production@2.0.1564'
};